import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Img from "gatsby-image";
import Content, { HTMLContent } from "../components/Content";
import SubscribeModal from "../components/SubscribeModal.jsx";

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  seanImage,
  traceyImage,
  bannerImage1,
  bannerImage2,
  bannerImage3,
  bannerImage4
}) => {
  // const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient pt-0">
      <SubscribeModal />
      <div
        className="about-banner container-fluid"
        style={{
          marginLeft: "calc(100% - 100vw)",
          width: "100vw"
        }}
      >
        <div className="row pb-3">
          <div className="col-3 p-0">
            <Img fluid={bannerImage1} />
          </div>
          <div className="col-3 p-0">
            <Img fluid={bannerImage2} />
          </div>
          <div className="col-3 p-0">
            <Img fluid={bannerImage3} />
          </div>
          <div className="col-3 p-0">
            <Img fluid={bannerImage4} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section pt-4">
              <div className="content">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light pb-4">
                  Host - Sean Burke
                </h2>
                <div className="row">
                  <div className="col-4 mt-1">
                    <Img fluid={seanImage} />
                  </div>
                  <div className="col-8 text-justify">
                    <p>
                      Sean Burke is the founder of the nonprofit Reset Our Gov,
                      which encourages Americans to press the “reset button” and
                      take back control of their country, an idea based on his
                      book How To Press America’s Reset Button.
                    </p>
                    <p>
                      He also founded the Paparazzi Reform Initiative, a privacy
                      rights nonprofit responsible for passing four new privacy
                      laws in just five years in the state of California.
                    </p>
                    <p>
                      Burke has appeared on such programs as the Today Show, the
                      CBS Evening News and Sky News and has been interviewed by
                      the Wall Street Journal, the Washington Post, USA Today,
                      Los
                    </p>
                    <p>
                      He’s also the host of the Sean Burke Show, an hour-long
                      talk-show first broadcast in New Hampshire on WSMN 1590 AM
                      in Nashua, and WLMW 90.7 FM in Manchester and Concord, and
                      then distributed as a podcast via iTunes and Soundcloud
                      through social media and to the show’s subscribers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="section pb-4">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light pb-3">
                  Producer - Tracey Andruscavage
                </h2>
                <div className="content">
                  <div className="row">
                    <div className="col-4 mt-1">
                      <Img fluid={traceyImage} />
                    </div>
                    <div className="col-8 text-justify">
                      <p>
                        Tracey has had a love of radio since DJing her own early
                        morning college radio shows.  With a BA in Theater, Film
                        and Television from UCLA, she has previously worked in
                        development in the film industry.
                      </p>
                      <p>
                        Along with Sean, she has created the show’s Changemaker
                        Series featuring people who make positive change in the
                        world on scales both large and small.  Her intention is
                        to inspire listeners to make positive change at any
                        level by showcasing people who are doing just that.
                      </p>
                      <p>
                        When not researching different shows and booking
                        interviews, she can be found developing real estate,
                        globetrotting with her kids and friends, and
                        volunteering in Scouting.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const AboutPage = ({ data } = {}) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        seanImage={data.seanImage.childImageSharp.fluid}
        traceyImage={data.traceyImage.childImageSharp.fluid}
        bannerImage1={data.cbsImage.childImageSharp.fluid}
        bannerImage2={data.newsmakerImage.childImageSharp.fluid}
        bannerImage3={data.insideEditionImage.childImageSharp.fluid}
        bannerImage4={data.skyNewsImage.childImageSharp.fluid}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    seanImage: file(relativePath: { eq: "sean.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    traceyImage: file(relativePath: { eq: "tracey.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cbsImage: file(relativePath: { eq: "cbs.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsmakerImage: file(relativePath: { eq: "newsmaker.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skyNewsImage: file(relativePath: { eq: "skyNews.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    insideEditionImage: file(relativePath: { eq: "insideEdition.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
